import ConfirmEmailPage from "../components/ConfirmEmail/ConfirmEmailPage";
import Background from "../components/SignUp/Background";
export default function ConfirmEmail() {
  return (
    <>
      <div className="flex flex-wrap min-h-screen">
        <Background />
        <ConfirmEmailPage />
      </div>
    </>
  );
}
