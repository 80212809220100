import CheckLogin from "../_helpers/auth-header";
import LicenseGeneratorForm from "../components/LicenseGenerator/LicenseGeneratorForm";
export default function LicenseGenerator() {

  return (
    <>
    <CheckLogin />
    <LicenseGeneratorForm />
    </>
  );


}
