import * as yup from "yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import RememberMe from "./RememberMe";
import FormControl from "./FormControl";
import SignInHeader from "./SignInHeader";
import SubmitButton from "./SubmitButton";
import FormContainer from "./FormContainer";
import ForgetPassword from "./ForgetPassword";
import { useSelector } from "react-redux";
import ErrorMessage from "../UI/ErrorMessage";
import { organizationNameActions } from "../../redux/slices/organizationNameSlice";
import { authActions } from "../../redux/index";
import { useNavigate } from "react-router-dom";
import SignUpButton from "./SignUpButton";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required")
    .email("Please enter a valid email address"),
  password: yup.string().required("This field is required"),
});

const SingInForm = () => {
  const {
    reset,
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "ldadmin@locationdas.com",
      password: "654123@@",
    },
  });

  const auth = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const togglePasswordHandler = () => setShowPassword((state) => !state);

  const signinHandler = (data) => {
    dispatch(authActions.SiginIn(data));
    dispatch(
      organizationNameActions.setOrganizationName({
        organizationName: "fullrays",
      })
    );

    // reset();
  };
  useEffect(() => {
    if (auth.token) {
      navigate("/roadmap");
    }
    setError(auth?.error);
    setSuccess(auth?.success);
  }, [auth.token, navigate]);
  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "false") {
      reset({
        email: "",
        password: "",
      });
    }
  }, [reset]);

  return (
    <FormContainer>
      <SignInHeader />
      {/* <p className="pb-5">
        <ErrorMessage message={auth?.error} />
      </p> */}

      {error && (
        <p className="pb-5">
          <ErrorMessage message={auth?.error} />
        </p>
      )}
      <form onSubmit={handleSubmit(signinHandler)}>
        <FormControl
          type="email"
          name="email"
          invalid={errors?.email}
          message={errors?.email?.message}
          register={{ ...register("email") }}
          placeholder="Email"
        />
        <FormControl
          name="password"
          invalid={errors?.password}
          showPassword={showPassword}
          message={errors?.password?.message}
          register={{ ...register("password") }}
          onTogglePassword={togglePasswordHandler}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
        />

        <div className="flex flex-wrap justify-between mb-11">
          <RememberMe />
          <ForgetPassword />
          <SignUpButton />
        </div>

        <SubmitButton />
      </form>
    </FormContainer>
  );
};

export default SingInForm;
