import VisibleIcon from "../Icons/Visible";
import InvisibleIcon from "../Icons/Invisible";
import ErrorMessage from "../UI/ErrorMessage";

const FormControl = ({
  label,
  name,
  type,
  invalid,
  message,
  register,
  showPassword,
  onTogglePassword,
  placeholder,
}) => {
  const isPassorwd = name === "password";

  const isInvalid = invalid ? "border-orange-2000" : "border-blue-2000/30";

  return (
    <div className="mb-2.5">
      <label htmlFor={name} className="block">
        <div className="text-blue-2000 mb-1.5">{label}</div>
        <div className={`w-full ${isPassorwd && "relative"}`}>
          <input
            id={name}
            type={type}
            name={name}
            {...register}
            className={`w-full px-6 h-12 outline-none border ${isInvalid} rounded-lg text-blue-2000 ${
              isPassorwd && "pr-11"
            }`}
            placeholder={placeholder}
          />
          {isPassorwd && (
            <button
              type="button"
              onClick={onTogglePassword}
              className="outline-none absolute right-4 top-0 bottom-0 my-auto h-6"
            >
              {showPassword ? <InvisibleIcon /> : <VisibleIcon />}
            </button>
          )}
        </div>
        <ErrorMessage message={message} />
      </label>
    </div>
  );
};

export default FormControl;
