import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organizationName: "",
};

const organizationNameSlice = createSlice({
  name: "organizationName",
  initialState,
  reducers: {
    setOrganizationName(state, action) {
      state.organizationName = action.payload.organizationName;
    },
  },
});

// Reducers and actions
export const organizationNameActions = organizationNameSlice.actions;

export default organizationNameSlice;
