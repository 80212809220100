import * as yup from "yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import FormControl from "../SignIn/FormControl";
import FormContainer from "../SignIn/FormContainer";
import { useSelector } from "react-redux";
import ErrorMessage from "../UI/ErrorMessage";
import { authActions } from "../../redux/index";
import ForgetPasswordHeader from "./ForgetPasswordHeader";
import ForgetPasswordButton from "./ForgetPasswordButton";
import authSlice from "../../redux/slices/authSlice";
import httpClient from "../../services/httpClient";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required")
    .email("Please enter a valid email address"),
});

const ForgetPasswordForm = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const auth = useSelector((state) => state.auth);
  const [requestState, setRequestState] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const forgetPasswordHandler = async (data) => {
    setIsLoading(true);
    const redirectUrl = "https://support.fullrays.com";
    const userData = {
      email: data.email,
      redirect_url: redirectUrl,
    };

    await httpClient
      .post("/request-reset-by-email/", userData)
      .then((res) => {
        setSuccess(res?.data?.success);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });

    // reset();
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "false") {
      reset({
        email: "",
        password: "",
      });
    }
  }, [reset]);

  return (
    <FormContainer>
      <ForgetPasswordHeader />
      {success && <p className="pb-5 text-green-500">{success}</p>}
      {error && (
        <p className="pb-5">
          <ErrorMessage message={error} />
        </p>
      )}

      <form onSubmit={handleSubmit(forgetPasswordHandler)}>
        <FormControl
          type="email"
          name="email"
          invalid={errors?.email}
          message={errors?.email?.message}
          register={{ ...register("email") }}
          placeholder="Email"
        />

        <div className="flex flex-wrap justify-between mb-11"></div>

        <ForgetPasswordButton isLoading={isLoading} />
      </form>
    </FormContainer>
  );
};

export default ForgetPasswordForm;
