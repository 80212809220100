const RememberMe = () => {
	return (
		<div className="w-auto">
			<label htmlFor="remember" className="flex items-center cursor-pointer">
				<input
					id="remember"
					name="remember"
					type="checkbox"
					className="form-checkbox mr-2.5 text-black border-blue-2000/30 checked:border-black hover:checked:border-blue-2000/30 focus:checked:border-blue-2000/30 bg-transparent focus:ring-0 focus:ring-offset-0 cursor-pointer"
				/>
				<span>Remember me</span>
			</label>
		</div>
	);
};

export default RememberMe;
