import React, { useState, useEffect } from "react";

export const AuthContext = React.createContext({
	auth: false,
	login: () => {},
	logout: () => {},
});

export const AuthContextProvider = props => {
	const [isAuth, setIsAuth] = useState(false);

	useEffect(() => {
		const loggedIn = localStorage.getItem("isAuth");

		if (loggedIn) {
			setIsAuth(true);
		}
	}, []);

	const loginHandler = () => {
		setIsAuth(true);

		localStorage.setItem("isAuth", true);
	};

	const logoutHandler = () => {
		setIsAuth(false);
		localStorage.removeItem("isAuth");
	};

	const vlaues = {
		auth: isAuth,
		login: loginHandler,
		logout: logoutHandler,
	};

	return (
		<AuthContext.Provider value={vlaues}>{props.children}</AuthContext.Provider>
	);
};
