const Spinner = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="text-white fill-current w-30px h-30px animate-spin"
      viewBox="0 0 30 30"
      {...props}
    >
      <path d="M16.5 1.5v3a1.5 1.5 0 1 1-3 0v-3a1.5 1.5 0 1 1 3 0zm-5.45 23.343l-1.5 2.599a1.5 1.5 0 1 1-2.6-1.5l1.5-2.599a1.5 1.5 0 1 1 2.6 1.5zm5.45.657v3a1.5 1.5 0 1 1-3 0v-3a1.5 1.5 0 1 1 3 0zM30 15a1.5 1.5 0 0 1-1.5 1.5h-3a1.5 1.5 0 1 1 0-3h3A1.5 1.5 0 0 1 30 15zm-6.95 10.942a1.5 1.5 0 0 1-2.6 1.5l-1.5-2.599a1.5 1.5 0 1 1 2.6-1.5zM27.99 22.5a1.5 1.5 0 0 1-2.05.55l-2.598-1.5a1.5 1.5 0 1 1 1.5-2.6l2.599 1.5a1.5 1.5 0 0 1 .549 2.05zM19.5 7.206a1.501 1.501 0 0 1-.55-2.049l1.5-2.599a1.5 1.5 0 1 1 2.6 1.5l-1.5 2.599a1.5 1.5 0 0 1-2.05.55zm3.843 1.245l2.599-1.5a1.5 1.5 0 1 1 1.5 2.598l-2.599 1.5a1.5 1.5 0 0 1-1.5-2.598zM4.5 16.5h-3a1.5 1.5 0 1 1 0-3h3a1.5 1.5 0 1 1 0 3zm-2.491-9a1.5 1.5 0 0 1 2.05-.55l2.598 1.5a1.5 1.5 0 1 1-1.5 2.6l-2.599-1.5A1.5 1.5 0 0 1 2.01 7.5zm5.197 12a1.501 1.501 0 0 1-.549 2.05l-2.599 1.5a1.5 1.5 0 0 1-1.5-2.6l2.599-1.5a1.5 1.5 0 0 1 2.05.55z" />
    </svg>
  );
};

export default Spinner;
