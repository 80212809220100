import { useSelector } from "react-redux";
import React, { useReducer } from "react";

import ConfirmEmailContainer from "./ConfirmEmailContainer";
import ConfirmEmailHeader from "./ConfirmEmailHeader";
import SpinnerIcon from "../Icons/Spinner";
import { useState, useEffect, useMemo } from "react";
import httpClient from "../../services/httpClient";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../UI/ErrorMessage";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

const ConfirmEmailPage = () => {
  const [formData, setFormData] = useReducer(formReducer, {});
  const [formErrors, setFormErrors] = useState();

  const { isLoading } = useSelector((state) => state.auth);
  const [submitting, setSubmitting] = useState(false);
  let navigate = useNavigate();

  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubmitting(true);
    try {
      //{{host}}/email-verify/?token=
      const { data, status } = await httpClient.get(
        `/email-verify/?token=${formData.token}`,
        {
          validateStatus: () => true,
        }
      );

      if (status === 200) {
        navigate("/roadmap");
      } else {
        setFormErrors(data.error);
      }
    } catch (err) {
      throw err;
    }
    setSubmitting(false);
  };
  return (
    <ConfirmEmailContainer>
      <ConfirmEmailHeader />
      <p className="pb-5"></p>
      <form className="mb-2.5" onSubmit={handleSubmit}>
        <ErrorMessage message={formErrors?.token?.message || formErrors} />
        <input
          type="text"
          name="token"
          onChange={handleChange}
          className="w-full px-6 h-12 outline-none border ${isInvalid} rounded-lg text-blue-2000"
        />
        <div className="flex flex-wrap">
          {isLoading ? (
            <button
              type="submit"
              className="outline-none bg-light-blue-2000 hover:bg-light-blue-2000/80 w-auto h-12 mx-auto text-white font-medium px-4 py-3 rounded-full cursor-pointer flex items-center justify-center mt-2.5"
            >
              <SpinnerIcon />
            </button>
          ) : (
            <button
              type="submit"
              className="outline-none bg-light-blue-2000 hover:bg-light-blue-2000/80 w-full h-12 text-white font-medium px-4 py-3 rounded-lg cursor-pointer"
            >
              Confirm
            </button>
          )}
        </div>
      </form>
    </ConfirmEmailContainer>
  );
};

export default ConfirmEmailPage;
