import { Link } from "react-router-dom";

const SignUpButton = () => {
  return (
    <div className="w-auto">
      <Link
        className="text-light-blue-2000 hover:text-gray-2000 transition-all"
        to="/signup"
      >
        Sign Up
      </Link>
    </div>
  );
};

export default SignUpButton;
