import * as yup from "yup";
import { useState, useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import httpClient from "../../services/httpClient";
import Select, { NonceProvider } from "react-select";
import countryList from "react-select-country-list";
import { yupResolver } from "@hookform/resolvers/yup";
import FormControl from "./FormControl";
import SignInHeader from "./SignInHeader";
import SubmitButton from "./SubmitButton";
import FormContainer from "./FormContainer";
import { useSelector } from "react-redux";
import ErrorMessage from "../UI/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../../index.css";
const regTemplate = {
  username: "wael",
  email: "wael@locationdas.com",
  phoneNumber: "+20-1119992948",
  password: "123123123",
  firstName: "Wael",
  lastName: "Hamed",
  avatar: "",
  permissions: [],
  roles: ["Engineer"],
};

const schema = yup.object().shape({
  username: yup.string().required("Username field is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  password: yup.string().required("Password is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  selectedCountry: yup.mixed().required("Country is required"),
  tosagree: yup
    .bool() // use bool instead of boolean
    .oneOf([true], "You must accept the terms and conditions"),
});

const RegistrationForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const auth = useSelector((state) => state.auth);

  const [selectedCountry, setSelectedCountry] = useState("");

  const [formErrors, setFormErrors] = useState();
  const options = useMemo(() => {
    return countryList().getData();
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  let navigate = useNavigate();
  const togglePasswordHandler = () => setShowPassword((state) => !state);

  const registrationHandler = (data) => {
    data = {
      ...data,
      country: selectedCountry,
    };

    registerNewUser(data);
  };

  const registerNewUser = async (userData) => {
    try {
      const { data, status } = await httpClient.post("/register/", userData, {
        validateStatus: () => true,
      });

      if (status === 201) {
        navigate("/signin");
      } else {
        setFormErrors(data.errors);
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (auth.token) {
      navigate("/roadmap");
    }
  }, [auth.token, navigate]);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      padding: 0,
      maxHeight: 48,
      borderRadius: 8,
      border: 0,
      boxShadow: "none",
    }),
    container: (styles) => ({
      ...styles,
      maxHeight: 48,
    }),
    valueContainer: (styles) => ({
      ...styles,
      paddingLeft: 24,
      paddingRight: 24,
    }),
    Input: (styles) => ({
      ...styles,
      margin: 0,
      padding: 0,
    }),
  };

  const [phoneNumber, setphoneNumber] = useState();

  return (
    <FormContainer>
      <SignInHeader />
      <p className="pb-5">
        <ErrorMessage message={auth?.error} />
      </p>
      <form onSubmit={handleSubmit(registrationHandler)}>
        <FormControl
          type="text"
          name="username"
          invalid={errors?.username || formErrors?.username}
          message={errors?.username?.message || formErrors?.username}
          register={{ ...register("username") }}
          placeholder="User Name"
        />
        <FormControl
          type="email"
          name="email"
          invalid={errors?.email || formErrors?.email}
          message={errors?.email?.message || formErrors?.email}
          register={{ ...register("email") }}
          placeholder="Email"
        />
        <FormControl
          name="password"
          invalid={errors?.password || formErrors?.password}
          showPassword={showPassword}
          message={errors?.password?.message || formErrors?.password}
          register={{ ...register("password") }}
          onTogglePassword={togglePasswordHandler}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
        />
        <div className="mb-2.5">
          <Controller
            name="phoneNumber"
            control={control}
            register={{ ...register("phoneNumber") }}
            render={({ field: { onChange, value } }) => {
              return (
                <PhoneInputWithCountrySelect
                  value={value}
                  className={`px-6 ${
                    errors?.phoneNumber
                      ? `border border-orange-2000 h-12 rounded-lg`
                      : `border border-blue-2000/30 h-12 rounded-lg`
                  }`}
                  placeholder="Phone Number"
                  onChange={(val) => {
                    setphoneNumber(val);
                    onChange(val);
                  }}
                />
              );
            }}
          />
          <ErrorMessage
            message={errors?.phoneNumber?.message || formErrors?.phoneNumber}
          />
        </div>

        <div className="mb-2.5">
          <Controller
            name="selectedCountry"
            control={control}
            register={{ ...register("selectedCountry") }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={options}
                placeholder="Select a Country"
                styles={customStyles}
                className={`${
                  errors?.selectedCountry
                    ? `border border-orange-2000 h-12 rounded-lg`
                    : `border border-blue-2000/30 h-12 rounded-lg`
                }`}
                value={options.find((c) => c.value === value)}
                onChange={(val) => {
                  setSelectedCountry(val.value);
                  onChange(val.value);
                }}
              />
            )}
          />
          <ErrorMessage
            message={
              errors?.selectedCountry?.message || formErrors?.selectedCountry
            }
          />
        </div>

        <div className="flex flex-wrap justify-between mb-11">
          <div className="w-auto">
            <label
              htmlFor="tosagree"
              className="flex items-center cursor-pointer"
            >
              <input
                {...register("tosagree")}
                id="tos-agree"
                type="checkbox"
                className="form-checkbox mr-2.5 text-black border-blue-2000/30 checked:border-black hover:checked:border-blue-2000/30 focus:checked:border-blue-2000/30 bg-transparent focus:ring-0 focus:ring-offset-0 cursor-pointer"
              />
              <p> By signing up, You agree to the &nbsp;
              <a href="https://www.fullrays.com/terms-and-conditions" className="text-blue-500" target={'_blank'} rel="noreferrer">
                Terms and Conditions
              </a>
              </p>
            </label>
            <ErrorMessage message={errors?.tosagree?.message} />
          </div>

          <div className="w-auto">
            <Link
              className="text-light-blue-2000 hover:text-gray-2000 transition-all"
              to="/signin"
            >
              Sign in
            </Link>
          </div>
        </div>

        <SubmitButton signup />
      </form>
    </FormContainer>
  );
};

export default RegistrationForm;
