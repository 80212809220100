import BugRequestForm from "../components/BugRequest/BugRequestForm";
import CheckLogin from "../_helpers/auth-header";
export default function BugRequest() {
  return (
    <>
      <CheckLogin />
      <BugRequestForm />
    </>
  );
}
