const Lock = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-38px h-38px text-light-blue-2000 fill-current"
      viewBox="0 0 30 38"
      {...props}
    >
      <path d="M26.25 18.748c0-1.035-.84-1.875-1.875-1.875H5.625c-1.036 0-1.875.84-1.875 1.875v13.125c0 1.036.84 1.875 1.875 1.875h18.75c1.036 0 1.875-.84 1.875-1.875zm3.75 0v13.125a5.625 5.625 0 0 1-5.625 5.625H5.625A5.625 5.625 0 0 1 0 31.873V18.748a5.625 5.625 0 0 1 5.625-5.625v-3.75a9.375 9.375 0 0 1 15.994-6.637 9.544 9.544 0 0 1 2.456 4.294 1.878 1.878 0 1 1-3.638.937 5.775 5.775 0 0 0-1.462-2.587 5.625 5.625 0 0 0-9.6 3.993v3.75h15A5.625 5.625 0 0 1 30 18.748zm-15 1.875a2.794 2.794 0 0 1 1.875 4.894v2.606a1.875 1.875 0 0 1-3.75 0v-2.606A2.794 2.794 0 0 1 15 20.623z" />
    </svg>
  );
};

export default Lock;
