import Background from "../components/SignIn/Background";
import ResetPasswordCodeConfirmationForm from "../components/ResetPasswordCodeConfirmation/ResetPasswordCodeConfirmationForm";

export default function ResetPasswordCodeConfirmation() {
  return (
    <>
      <div className="flex flex-wrap min-h-screen">
        <Background />
        <ResetPasswordCodeConfirmationForm />
      </div>
    </>
  );
}
