import Background from "../components/SignIn/Background";
import ForgetPasswordForm from "../components/ForgetPassword/ForgetPassword";
export default function ForgetPassword() {
  return (
    <>
      <div className="flex flex-wrap min-h-screen">
        <Background />
        <ForgetPasswordForm />
      </div>
    </>
  );
}
