import axios from "axios";

//export const API_URL = "https://pm-be-dev.locationdas.com/api";
export const API_URL = "https://ldpm-backend-001.herokuapp.com/api";
//export const API_URL = "http://localhost:8000/api";

let token =
  typeof window !== "undefined"
    ? JSON.parse(window.localStorage.getItem("x-auth-token"))?.access
    : null;

const instance = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

//console.log(
 // `Process Environment ${JSON.stringify(process.env.API_URL)}`
//);
//console.log(`Token is ${token}`);
// instance.defaults.headers.post["Content-Type"] = "application/json";

export default instance;
