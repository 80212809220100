import FeatureRequestForm from "../components/FeatureRequest/FeatureRequestForm";
import CheckLogin from "../_helpers/auth-header";

export default function FeatureRequest() {
  return (
    <>
      <CheckLogin />
      <FeatureRequestForm />
    </>
  );
}
