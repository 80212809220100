import { Fragment } from "react";
import LockIcon from "../Icons/Lock";

const SignInHeader = () => {
	return (
		<Fragment>
			<div className="mb-2.5">
				<LockIcon />
			</div>
			<h3 className="text-blue-2000 text-2xl lg:text-[34px] font-semibold mb-12">
			Registration
			</h3>
		</Fragment>
	);
};

export default SignInHeader;
