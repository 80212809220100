import { Link } from "react-router-dom";
const ForgetPassword = () => {
  return (
    <div className="w-auto">
      <Link
        className="text-light-blue-2000 hover:text-gray-2000 transition-all"
        to="/forget-password"
      >
        Forgot password?
      </Link>
    </div>
  );
};

export default ForgetPassword;
