import { Fragment } from "react";
import LockIcon from "../Icons/Lock";

const ConfirmEmailHeader = () => {
  return (
    <Fragment>
      <div className="mb-2.5">
        <LockIcon />
      </div>
      <h3 className="text-blue-2000 text-2xl lg:text-[34px] font-semibold mb-12">
        Confirm Email
      </h3>
      <p className="mb-12 text-blue-2000 text-2xl lg:text-[34px] font-semibold text-center">
        We've sent you an email to confirm your account , please enter the
        received code{" "}
      </p>
    </Fragment>
  );
};

export default ConfirmEmailHeader;
