import { useSelector } from "react-redux";
import SpinnerIcon from "../Icons/Spinner";
const ForgetPasswordButton = ({ isLoading }) => {
  return (
    <div className="flex flex-wrap">
      {isLoading ? (
        <button
          type="submit"
          className="outline-none bg-light-blue-2000 hover:bg-light-blue-2000/80 w-auto h-12 mx-auto text-white font-medium px-4 py-3 rounded-full cursor-pointer flex items-center justify-center mt-2.5"
        >
          <SpinnerIcon />
        </button>
      ) : (
        <button
          type="submit"
          className="outline-none bg-light-blue-2000 hover:bg-light-blue-2000/80 w-full h-12 text-white font-medium px-4 py-3 rounded-lg cursor-pointer"
        >
          Send email
        </button>
      )}
    </div>
  );
};

export default ForgetPasswordButton;
