import Background from "../components/SignUp/Background";
import RegistrationForm from "../components/SignUp/RegistrationForm";

export default function SignUp() {
  return (
    <>
      <div className="flex flex-wrap min-h-screen">
        <Background />
        <RegistrationForm />
      </div>
    </>
  );
}
