import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../redux/action-creators/auth.actionCreator";
export default function CheckLogin(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
   
    useEffect(()=>{
      if(!localStorage.getItem("x-auth-token")){
        dispatch(logout());
        navigate('/signin') 
      }else{
        setLoading(false);
      }
    }, [dispatch, navigate])
  
  if(loading){
      return(<></>)
  }
}