import { AuthContextProvider } from "./context/auth-context";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import Navbar from "./components/Navbar/Navbar";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import FeatureRequest from "./pages/feature-request";
import BugRequest from "./pages/bug-request";
import { Routes, Route } from "react-router-dom";
import Roadmap from "./pages/roadmap";
import "./index.css";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import LicenseGenerator from "./pages/license-generator";
import ConfirmEmail from "./pages/confirm-email";
import Error404Page from "./pages/error404";
import ResetPassword from "./pages/forget-password";
import ForgetPassword from "./pages/forget-password";
import ResetPasswordCodeConfirmation from "./pages/reset-password-code-confirmation";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <AuthContextProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Roadmap />} />
            <Route path="feature-request" element={<FeatureRequest />} />
            <Route path="bug-request" element={<BugRequest />} />
            <Route path="roadmap" element={<Roadmap />} />
            <Route path="license-generator" element={<LicenseGenerator />} />
            <Route path="signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route
              path="/reset-password"
              element={<ResetPasswordCodeConfirmation />}
            />
            <Route path="*" element={<Error404Page />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </AuthContextProvider>
);
