/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "../../images/logo-fullrays.svg";
import { logout } from "../../redux/action-creators/auth.actionCreator";

const navigation = [
  { name: "Roadmap", href: "roadmap", current: false },
  { name: "Feature Request", href: "feature-request", current: false },
  { name: "Bug Report", href: "bug-request", current: false },
  { name: "License Generator", href: "license-generator", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [showNav, setShowNav] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function logoutHandler() {
    dispatch(logout());
    navigate("/signin");
  }

  const { pathname } = useLocation();

  // useEffect(() => {
  //   if (
  //     pathname === "/roadmap" ||
  //     pathname === "/feature-request" ||
  //     pathname === "/bug-request" ||
  //     pathname === "license-generator"
  //   ) {
  //     setShowNav(true);
  //   } else {
  //     setShowNav(false);
  //   }
  // }, [pathname]);

  // useEffect(() => {
  //   if (!localStorage.getItem("x-auth-token") && pathname === "*") {
  //     setShowNav(false);
  //     console.log(showNav);
  //   } else if (
  //     pathname === "signin" ||
  //     pathname === "signup" ||
  //     pathname == "confirm-email" ||
  //     pathname === ""
  //   ) {
  //     console.log(showNav);
  //   } else {
  //     setShowNav(true);
  //     console.log(showNav);
  //   }
  // });

  useEffect(() => {
    if (localStorage.getItem("x-auth-token")) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  });

  return (
    <>
      {showNav && (
        <Disclosure
          as="nav"
          className="bg-white shadow-md border-b z-50 relative"
        >
          {({ open }) => (
            <>
              <div className=" mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                  <div className="absolute inset-y-0 left-0 flex items-center  sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-light-blue-2000  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex-shrink-0 flex items-center">
                      {/* <img
                    className="block lg:hidden h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                    alt="Workflow"
                  /> */}
                      <Link to="/roadmap" className="flex    items-center mr-7">
                        <img src={Logo} alt={`fullrays`} className="h-9" />
                        <span className="font-stem-medium font-semibold text-blue-2000 text-2xl ml-2  sm:flex">
                          FullRays
                        </span>
                      </Link>
                    </div>
                    <div className="hidden sm:block sm:ml-6">
                      <div className="flex space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "px-3 py-2  text-blue-2000 hover:text-gray-2002 text-sm"
                                : "text-light-blue-2000 hover:bg-gray-700 hover:text-white",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <button
                    className="text-white bg-light-blue-2000 p-2 rounded-md cursor-pointer"
                    onClick={logoutHandler}
                  >
                    logout
                  </button>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "block px-3 py-2 rounded-md text-dark-blue-2000 text-base font-medium font-stem-medium-italic "
                          : "block px-3 py-2 rounded-md text-dark-blue-2000 text-base font-medium font-stem-medium-italic ",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
}
