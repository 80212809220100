import { useSelector } from "react-redux";
import logo from "../../images/logo1.png";
const Background = () => {
  const organizationName = useSelector(
    (state) => state.organizationName.organizationName
  );
  return (
    <div className="bg-light-blue-2002 bg-none lg:bg-form-box bg-left-bottom bg-no-repeat w-full lg:w-1/2 flex items-center justify-center px-6 py-8">
      <div className="w-full lg:w-345px mx-auto mb-0 lg:mb-20">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default Background;
