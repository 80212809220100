import instance from "../../services/httpClient";
import httpClient from "../../services/httpClient";
import { authActions } from "../slices/authSlice";
import { push } from "react-router-redux";

export const SiginIn = (userData) => {
  return async (dispatch) => {
    dispatch(authActions.authRequest());

    try {
      const { data } = await httpClient.post("/token/", userData);
      localStorage.setItem("x-auth-token", JSON.stringify(data));
      instance.defaults.headers["Authorization"] = `Bearer ${data.access}`;
      const me = await httpClient.get("/me/");
      data.user = me.data;
      dispatch(authActions.authSuccess(data));
    } catch (err) {
      dispatch(authActions.authFaild(err.response?.data?.detail));
    }
  };
};

export const register = (userData) => {
  return async (dispatch) => {
    dispatch(push("/"));

    //dispatch(authActions.authRequest());
    try {
      const { data, status } = await httpClient.post("/usersv2/", userData, {
        validateStatus: () => true,
      });
      if (status === 201) {
        //   dispatch(authActions.authSuccess(data));
        dispatch(push("/"));
      }
    } catch (err) {
      //dispatch(authActions.authFaild(err.response?.data?.detail));
    }
  };
};

// export const siginUp = (userData) => {
//   return async (dispatch) => {
//     dispatch(authActions.authRequest());

//     try {
//       const { data } = await httpClient.post("/token/", userData);

//       dispatch(authActions.authSuccess(data));
//       localStorage.setItem("x-auth-token", JSON.stringify(data));
//       const me = await httpClient.get("/me/");
//       authActions.presistTokens({ access: token, refresh, user: me.data });
//       dispatch(
//         authActions.presistTokens({ access: token, refresh, user: data })
//       );
//     } catch (err) {
//       console.log(err);
//       dispatch(authActions.authFaild(err.response?.data?.message));
//     }
//   };
// };

export const getRefreshToken = (refreshToken) => {
  return async (dispatch) => {
    dispatch(authActions.authRequest());

    try {
      const { data } = await httpClient.post("/token/refresh/", refreshToken);

      dispatch(authActions.authSuccess({ refresh: data.refresh }));

      localStorage.setItem("x-auth-token", JSON.stringify(data));
    } catch (err) {
      dispatch(authActions.authFaild());
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(authActions.authLogout());
    localStorage.removeItem("x-auth-token");
  };
};

export const getAuthData = () => {
  return async (dispatch) => {
    dispatch(authActions.authRequest());

    const authData = JSON.parse(localStorage.getItem("x-auth-token"));

    try {
      const { data } = await httpClient.get("/me/");

      const token = authData?.access || null;
      const refresh = authData?.refresh || null;

      dispatch(
        authActions.presistTokens({ access: token, refresh, user: data })
      );
    } catch (error) {
      dispatch(authActions.authFaild());
    }
  };
};

// export const forgetPassword = (userEmail) => {
//   return async (dispatch) => {
//     dispatch(authActions.forgetPassword());

//   };
// };

// export const codeConfirmation = (userData) => {
//   return async (dispatch) => {
//     // dispatch(authActions.authRequest());
//     dispatch(authActions.CodeConfirmation());
//     await httpClient
//       .post("/password-reset/", userData)
//       .then(function (response) {
//         dispatch(authActions.CodeConfirmationSuccess(response?.data?.success));
//       })
//       .catch(function (err) {
//         dispatch(authActions.CodeConfirmationFailed(err?.request?.response));
//       });
//   };
// };

// export const resetPassword = (userData) => {
//   return async (dispatch) => {
//     // dispatch(authActions.authRequest());
//     dispatch(authActions.ResetPassword());

//     await httpClient
//       .post("/password-reset-complete/", userData)
//       .then(function (response) {
//         console.log(response);
//         dispatch(authActions.ResetPasswordSuccess(response?.data?.success));
//       })
//       .catch(function (err) {
//         console.log(err);
//         dispatch(authActions.ResetPasswordFailed(err?.request?.response));
//       });
//   };
// };
