export default function LicenseGeneratorForm() {
    return (
      <div className="flex flex-col justify-start items-center h-full w-full">
        <iframe
          className="airtable-embed bg-transparent "
          src="https://airtable.com/embed/shrgTyKhRjc0mwkJQ?backgroundColor=orange"
          width="100%"
          height="900"
          title="bug-request"
        ></iframe>
      </div>
    );
  }
  