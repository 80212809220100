import { Fragment } from "react";

import Background from "../components/SignIn/Background";
import SingInForm from "../components/SignIn/SingInForm";

export default function SignIn() {
  return (
    <>
      <div className="flex flex-wrap min-h-screen">
        <Background />
        <SingInForm />
      </div>
    </>
  );
}
