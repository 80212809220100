import { useSelector } from "react-redux";
import logo from "../../images/logo1.png";
const Background = () => {
  const organizationName = useSelector(
    (state) => state.organizationName.organizationName
  );
  return (
    <div className="bg-light-blue-2002 bg-none lg:bg-form-box bg-left-bottom bg-no-repeat w-full lg:w-1/2 flex items-center justify-center px-6 py-8">
      <div className="w-full lg:w-345px mx-auto mb-0 lg:mb-20">
        <img src={logo} />
        {/* <img
            alt="LocationDAS"
            className="h-[72px]"
            src={`/images/build/img/logo-fullrays-2.svg`}
          /> */}
        {/* <span className="font-poppins font-semibold text-blue-2000 text-2xl lg:text-[40px] ml-2 flex">
            FullRays
          </span> */}
        {/* <p className="text-blue-2000 text-base xl:text-lg font-extralight ml-[82px] -mt-8 lg:-mt-6 xl:-mt-5">
          Inbuilding Re-Im agined
        </p> */}
      </div>
    </div>
  );
};

export default Background;
