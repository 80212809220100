import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  error: null,
  isLoading: false,
  refreshToken: null,
  success: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    authSuccess(state, action) {
      state.isLoading = false;
      state.token = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.user = action.payload.user;
      state.error = null;
    },
    authFaild(state, action) {
      state.token = null;
      state.isLoading = false;
      state.refreshToken = null;
      state.error = action.payload;
    },
    authLogout(state) {
      state.token = null;
      state.isLoading = false;
      state.refreshToken = null;
    },
    presistTokens(state, action) {
      state.isLoading = false;
      state.token = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.user = action.payload.user;
    },
  },
});

// Reducers and actions
export const authActions = authSlice.actions;

export default authSlice;
