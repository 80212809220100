import * as yup from "yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import FormControl from "../SignIn/FormControl";
import SubmitButton from "../SignIn/SubmitButton";
import FormContainer from "../SignIn/FormContainer";
import { useSelector } from "react-redux";

import ForgetPasswordHeader from "../ForgetPassword/ForgetPasswordHeader";
import { useLocation, useNavigate } from "react-router-dom";
import httpClient from "../../services/httpClient";

const schema = yup.object().shape({
    email: yup
        .string()
        .required("This field is required")
        .email("Please enter a valid email address"),
    password: yup.string().required("This field is required"),
});
const ResetPasswordCodeConfirmationForm = () => {
    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const location = useLocation();
    let navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [requestState, setRequestState] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [tokenValidatedError, setTokenValidatedError] = useState();
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    let params = location.search.split("&");
    let uidb64 = params[0];
    let token = params[1];
    token = token.split("token=")[1];
    uidb64 = uidb64.split("?")[1];
    uidb64 = uidb64.split("uidb64=")[1];

    useEffect(() => {
        async function validatedToken() {
            const userData = { token: token, uidb64: uidb64 };
            setRequestState(auth);
            await httpClient
                .post("/password-reset/", userData)
                .then(function (response) {
                    setConfirmed(true);
                })
                .catch(function (err) {
                    setConfirmed(false);
                    setTokenValidatedError(err?.response?.data.error);
                });
        }
        validatedToken();
    }, []);

    const togglePasswordHandler = () => setShowPassword((state) => !state);

    const changePasswordHandler = async (data) => {
        const userData = {
            token: token,
            uidb64: uidb64,
            email: data.email,
            password: data.password,
        };

        setIsLoading(true);
        await httpClient
            .post("/password-reset-complete/", userData)
            .then(function (response) {
                setSuccess(response?.data?.message);
                setIsLoading(false);
                setTimeout(() => {
                    navigate("/signin");
                }, 3000);
            })
            .catch(function (err) {
                setError(err?.response?.data?.detail);
                setIsLoading(false);
            });
    };
    return (
        <FormContainer>
            {!confirmed && tokenValidatedError && (
                <p className="text-orange-2000 text-xs mt-0.5 pb-5">
                    {tokenValidatedError}
                </p>
            )}
            {confirmed && (
                <>
                    <ForgetPasswordHeader />

                    {success && (
                        <p className="pb-5 text-green-500">
                            Your password was changed successfully
                        </p>
                    )}

                    {error && (
                        <p className="text-orange-2000 text-xs mt-0.5 pb-5">
                            {error}
                        </p>
                    )}

                    <form onSubmit={handleSubmit(changePasswordHandler)}>
                        <FormControl
                            type="email"
                            name="email"
                            invalid={errors?.email}
                            message={errors?.email?.message}
                            register={{ ...register("email") }}
                            placeholder="Email"
                        />
                        <FormControl
                            type={showPassword ? "text" : "password"}
                            name="password"
                            invalid={errors?.password}
                            message={errors?.password?.message}
                            register={{ ...register("password") }}
                            showPassword={showPassword}
                            onTogglePassword={togglePasswordHandler}
                            placeholder="New Password"
                        />
                        <SubmitButton
                            title="Password reset"
                            isLoading={isLoading}
                        />
                    </form>
                </>
            )}
        </FormContainer>
    );
};

export default ResetPasswordCodeConfirmationForm;
