export default function BugRequestForm() {
  return (
    <div className="flex flex-col justify-start items-center h-full w-full">
      <iframe
        className="airtable-embed bg-transparent "
        src="https://airtable.com/embed/shrQVIEp349JKSQUY?backgroundColor=blue"
        width="100%"
        height="900"
        title="bug-request"
      ></iframe>
    </div>
  );
}
